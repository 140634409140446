import { Dispatch } from 'redux'
import { api } from '../../api'
import { ProductModelStateModel } from './ViewModel'
export * from './ViewModel'

// initial state
const initialProductModelState: ProductModelStateModel = {
  // isLoading: false,
  productModelsList: []
}

// reducer
export const productModelsReducer = (
  state: ProductModelStateModel = initialProductModelState,
  action: any
): ProductModelStateModel => {
  switch (action.type) {
    // combination
    case productModelActionTypes.PRODUCT_MODELS:
      return {
        ...action.data
      }

    default:
      return state
  }
}

export const productModelActionTypes = {
  // COMBINATION DETAILS
  PRODUCT_MODELS: 'PRODUCT_MODELS'
}

// action creators & async actions
export const productModelsActions = {
  getProductModels: (onSuccess?: () => void) => async (dispatch: Dispatch) => {
    // dispatch({ type: types.GET_COURSES_REQUEST });
    try {
      const response = await api.build.getProductModelList()

      await dispatch({
        type: productModelActionTypes.PRODUCT_MODELS,
        data: response
      })
      if (onSuccess) {
        onSuccess()
      }
    } catch (error) {
      // serializeAndShowFormErrors(error);
      throw error
    }
  }
}
